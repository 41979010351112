// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { Icon } from "@mui/material";
import FormField from "../FormField";

interface Props {
  updateCashUnit: Function;
  updateCashUnitCritical: Function;
  updateCashUnitExtra: Function;
  updatePercentageCritical: Function;
  updatePointCashExchangeRate: Function;

  cash_unit: number;
  cash_unit_critical: number;
  cash_unit_extra: number;
  percentage_critical: number;
  point_cash_exchange_rate: number;
}

function Rewards({
  updateCashUnit,
  updateCashUnitCritical,
  updateCashUnitExtra,
  updatePercentageCritical,
  updatePointCashExchangeRate,

  cash_unit,
  cash_unit_critical,
  cash_unit_extra,
  percentage_critical,
  point_cash_exchange_rate,
}: Props): JSX.Element {
  // cash_unit 광고 보상 point
  // cash_unit_critical 크리티컬 광고 보상
  // cash_unit_extra 일일 광고 클릭 제한 이후 추가 실행 시 광고 보상 point
  // percentage_critical 크리티컬 광고 노출 확률 (1/n)
  // point_cash_exchange_rate 포인트-캐시 교환비율

  const [localCashUnit, setLocalCashUnit] = useState<number>(cash_unit);
  const [localCashUnitCritical, setLocalCashUnitCritical] = useState<number>(cash_unit_critical);
  const [localCashUnitExtra, setLocalCashUnitExtra] = useState<number>(cash_unit_extra);
  const [localPercentageCritical, setLocalPercentageCritical] =
    useState<number>(percentage_critical);
  const [localPointCashExchangeRate, setLocalPointCashExchangeRate] =
    useState<number>(point_cash_exchange_rate);

  // 각 설정 값 변경 핸들러
  const handleCashUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalCashUnit(Number(e.target.value));
  };

  const handleCashUnitCriticalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalCashUnitCritical(Number(e.target.value));
  };

  const handleCashUnitExtraChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalCashUnitExtra(Number(e.target.value));
  };

  const handlePercentageCriticalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalPercentageCritical(Number(e.target.value));
  };

  const handlePointCashExchangeRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalPointCashExchangeRate(Number(e.target.value));
  };
  useEffect(() => {
    setLocalCashUnit(cash_unit);
    setLocalCashUnitCritical(cash_unit_critical);
    setLocalCashUnitExtra(cash_unit_extra);
    setLocalPercentageCritical(percentage_critical);
    setLocalPointCashExchangeRate(point_cash_exchange_rate);
  }, [
    cash_unit,
    cash_unit_critical,
    cash_unit_extra,
    percentage_critical,
    point_cash_exchange_rate,
  ]);

  return (
    <Card id="rewards">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">포인트 보상 상세 설정</MDTypography>
        </MDBox>

        <MDTypography variant="button" color="text">
          보상 설정.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            광고 보상 point
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <FormField
                type="number"
                label="cash_unit"
                value={localCashUnit}
                onChange={handleCashUnitChange}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => updateCashUnit(localCashUnit)}
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            크리티컬 광고 보상
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <FormField
                type="number"
                label="cash_unit_critical"
                value={localCashUnitCritical}
                onChange={handleCashUnitCriticalChange}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => updateCashUnitCritical(localCashUnitCritical)}
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            일일 광고 클릭 제한 이후 추가 실행 시 광고 보상 point
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <FormField
                type="number"
                label="cash_unit_extra"
                value={localCashUnitExtra}
                onChange={handleCashUnitExtraChange}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => updateCashUnitExtra(localCashUnitExtra)}
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            크리티컬 광고 노출 확률 (1/n)
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <FormField
                type="number"
                label="percentage_critical"
                value={localPercentageCritical}
                onChange={handlePercentageCriticalChange}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => updatePercentageCritical(localPercentageCritical)}
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            포인트-캐시 교환비율
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <FormField
                type="number"
                label="point_cash_exchange_rate"
                value={localPointCashExchangeRate}
                onChange={handlePointCashExchangeRateChange}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => updatePointCashExchangeRate(localPointCashExchangeRate)}
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}
Rewards.defaultProps = {
  cash_unit: 0,
  cash_unit_critical: 0,
  cash_unit_extra: 0,
  percentage_critical: 0,
  point_cash_exchange_rate: 0,
};
export default Rewards;
