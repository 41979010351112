// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Declaring props types for BannerCell
interface Props {
  media?: string;
  type?: string;
}

function BannerCell({ media, type }: Props): JSX.Element {
  const renderMedia = () => {
    switch (type) {
      case "shorts":
        return (
          <video
            controls={false}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}${media}`}
            width={80}
          />
        );
      case "video":
        return (
          <video
            controls={false}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}${media}`}
            width={160}
          />
        );
      case "image":
        return (
          <img
            src={`${process.env.REACT_APP_IMG_ENDPOINT}${media}`}
            alt="image_banner"
            width={160}
          />
        );
      case "live":
      case "ssp":
        return (
          <MDBox
            width={160}
            height={50}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ border: "1px solid #ccc", borderRadius: "4px" }}
          >
            {media}
          </MDBox>
        );
      default:
        return null;
    }
  };

  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1}>{renderMedia()}</MDBox>
    </MDBox>
  );
}

// Declaring default props for BannerCell
BannerCell.defaultProps = {
  media: "",
  type: "image",
};

export default BannerCell;
