import Apis from "apis/remotes";
import { INotice } from "apis/response";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Card, message } from "antd";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Divider } from "@mui/material";
import ListItem from "./components/ListItem";
import TextArea from "antd/es/input/TextArea";

function NoticeDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [notice, setNotice] = useState<INotice>();
  /** 공지 삭제 버튼 클릭 */
  const handleDeleteNotice = () => {
    Apis.deleteNoticeById(id)
      .then((resolve) => {
        navigate("/pages/notice/notice-list");
        message.success("공지가 제거되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /** 공지 수정 버튼 클릭 */
  // const handleNavigateUpdateNotice = () => {
  //   navigate(`/notice-management/update-notice-details/${id}`);
  // };
  useEffect(() => {
    Apis.getNoticeById(id)
      .then((resolve) => {
        setNotice(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <MDBox mb={2} lineHeight={1} display={"flex"} justifyContent={"space-between"}>
              <MDTypography variant="h4">{notice?.title}</MDTypography>
              <MDButton
                LinkComponent={Link}
                to={`/page/notice/update-notice/${id}`}
                color="info"
                size="small"
                // onClick={() => handleNavigateUpdateNotice()}
              >
                공지 수정
              </MDButton>
            </MDBox>
            <MDBox opacity={0.3}>
              <Divider />
            </MDBox>
            <MDBox>
              <ListItem label={"작성시간"} values={notice?.createdAt} />

              <TextArea value={notice?.content} readOnly autoSize={{ minRows: 7, maxRows: 10 }} />
            </MDBox>
          </MDBox>
          <MDBox p={3} display={"flex"} justifyContent={"end"} gap={2}>
            <MDButton color="error" size="small" onClick={() => handleDeleteNotice()}>
              공지 삭제
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default NoticeDetails;
