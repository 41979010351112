import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";

import { Input, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";

import TextArea from "antd/es/input/TextArea";

import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

interface NoticeSubmitForm {
  title: string;
  content: string;
}
function UpdateNotice(): JSX.Element {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleUpdateNotice = async (noticeForm: NoticeSubmitForm) => {
    setLoading(true);
    Apis.putNoticeById(id, {
      title: noticeForm.title,
      content: noticeForm.content,
    })
      .then((resolve) => {
        message.success("배너가 수정되었습니다.");
        navigate(`/page/notice/detail-notice/${id}`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    Apis.getNoticeById(id)
      .then((resolve) => {
        const noticeData = resolve.data;

        form.setFieldsValue({
          title: noticeData.title,
          content: noticeData.content,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Typography variant="h4">공지 수정</Typography>
          </MDBox>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={handleUpdateNotice}
              autoComplete="off"
            >
              <FormItem
                label="공지 제목"
                name="title"
                rules={[{ required: true, message: "제목을 입력하세요." }]}
              >
                <Input maxLength={50} showCount />
              </FormItem>
              <FormItem
                label="본문 내용"
                name="content"
                rules={[{ required: true, message: "내용을 입력하세요." }]}
              >
                <TextArea maxLength={255} showCount autoSize={{ minRows: 7, maxRows: 10 }} />
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Link to={`/page/notice/detail-notice/${id}`}>
                    <MDButton fullWidth variant="contained" color="light">
                      취소
                    </MDButton>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    disabled={loading}
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    수정
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default UpdateNotice;
