import { useEffect, useState } from "react";

// @mui material components
import { Card, Divider } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "../FormField";

interface Props {
  updateDayCount: Function;
  updateDayCountExtra: Function;

  day_count: number;
  day_count_extra: number;
}
function DailySavings({
  updateDayCount,
  updateDayCountExtra,
  day_count,
  day_count_extra,
}: Props): JSX.Element {
  //day_count 일일 적립 제한 횟수
  //day_count_extra 일일 광고 클릭 제한 이후 추가 횟수

  const [dayCount, setDayCount] = useState(day_count);
  const [dayCountExtra, setDayCountExtra] = useState(day_count_extra);
  const handleChangeDayCount = (e: any) => {
    console.log(e.currentTarget.value as number);
    setDayCount(e.currentTarget.value as number);
  };
  const handleChangeDayCountExtra = (e: any) => {
    console.log(e.currentTarget.value as number);
    setDayCountExtra(e.currentTarget.value as number);
  };

  useEffect(() => {
    setDayCount(day_count);
    setDayCountExtra(day_count_extra);
  }, [day_count, day_count_extra]);
  return (
    <Card id="daily_savings">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">일일 포인트 적립 제한</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            일일 적립 제한 횟수
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <FormField
                type="number"
                label="day_count"
                value={dayCount}
                onChange={handleChangeDayCount}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => updateDayCount(dayCount)}
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            일일 광고 클릭 제한 이후 추가 횟수
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <FormField
                type="number"
                label="day_count_extra"
                value={dayCountExtra}
                onChange={handleChangeDayCountExtra}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => updateDayCountExtra(dayCountExtra)}
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}
DailySavings.defaultProps = {
  day_count: 0,
  day_count_extra: 0,
};

export default DailySavings;
