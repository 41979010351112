// @mui material components
import { Card, Divider } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useState } from "react";
import FormField from "../FormField";

interface Props {
  postPointReward: Function;
}

function PointReward({ postPointReward }: Props): JSX.Element {
  // Local states for each field
  const [userId, setUserId] = useState<number>();
  const [amount, setAmount] = useState<number>();
  const [type, setType] = useState<string>("store review event");

  // Toggle editability
  const handleUserId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(Number(e.target.value));
  };
  const handleRewardAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value));
  };
  const handleRewardType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setType(String(e.target.value));
  };

  // 이벤트 리스너
  // useEffect(() => {
  //   setPercantage(alphabase_percantage);
  //   setDelayTimeSeconds(banner_delay_time_seconds);
  // }, [alphabase_percantage, banner_delay_time_seconds]);

  return (
    <Card id="point_reward">
      {/* Card content */}
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">유저 리워드 지급</MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="button" color="text">
            포인트 지급
          </MDTypography>
          <MDBox display="flex" alignItems="center">
            <MDBox mx={2}>
              <FormField type="number" label="user id" value={userId} onChange={handleUserId} />
              <FormField
                type="number"
                label="Reward amount"
                value={amount}
                onChange={handleRewardAmount}
              />
              <FormField
                type="string"
                label="Reward type"
                value={type}
                onChange={handleRewardType}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() =>
                postPointReward({
                  userId,
                  amount,
                  type,
                })
              }
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}
PointReward.defaultProps = {
  user_id: 0,
  amount: 0,
  type: "store review event",
};

export default PointReward;
