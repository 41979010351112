/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

interface Props {
  approveBanner: Function;
}
function BannerApproveCard({ approveBanner }: Props): JSX.Element {
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography color="secondary" fontSize="small">
          해당 배너는 광고주의 요청에 의해 정기 결제 예약 대기중입니다. 배너를 노출과 동시에
          정기결제가 시작됩니다.
        </MDTypography>
        <MDButton color="success" variant="contained" onClick={() => approveBanner()}>
          배너 정기 결제 및 노출 활성화
        </MDButton>
      </MDBox>
    </Card>
  );
}

export default BannerApproveCard;
