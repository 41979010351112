/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

import Apis from "apis/remotes";
import BaseLayout from "./components/BaseLayout";
import Sidenav from "./components/Sidenav";
import { IGetConfigure } from "apis/response";

import { App } from "antd";

import DailySavings from "./components/DailySavings";
import Rewards from "./components/Rewards";
import Banners from "./components/Banners";
import ResourceUrl from "./components/ResourceUrl";
import PointReward from "./components/PointReward";
import { IPostPointReward } from "apis/request";

function ConfigureSetting(): JSX.Element {
  const { message } = App.useApp();
  const [configure, setConfigure] = useState<IGetConfigure>();
  useEffect(() => {
    Apis.getConfig()
      .then((resolve) => {
        setConfigure(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /** 일일 적립 제한 횟수 */
  const updateDayCount = (value: any) => {
    Apis.putConfig({ key: "day_count", value }).then((resovle) => {
      message.success("Success!");
    });
  };
  /** 일일 광고 클릭 제한 이후 추가 횟수 */
  const updateDayCountExtra = (value: any) => {
    Apis.putConfig({ key: "day_count_extra", value }).then((resovle) => {
      message.success("Success!");
    });
  };

  // /** 애드몹 클릭 제한 횟수 */
  // const updateAdmobLimitCount = (value: any) => {
  //   Apis.putConfig({ key: "admob_limit_count", value }).then((resovle) => {
  //     message.success("Success!");
  //   });
  // };
  // /** 애드몹 클릭 제한 시간(초) */
  // const updateAdmobLimitHour = (value: any) => {
  //   Apis.putConfig({ key: "admob_limit_hour", value }).then((resovle) => {
  //     message.success("Success!");
  //   });
  // };
  // /** admob 광고 비율 */
  // const updateAdmobPercentage = (value: any) => {
  //   Apis.putConfig({ key: "admob_percentage", value }).then((resovle) => {
  //     message.success("Success!");
  //   });
  // };
  // /** 애드몹 게재 정지 시간(초) */
  // const updateAdmobStopTime = (value: any) => {
  //   Apis.putConfig({ key: "admob_stop_time", value }).then((resovle) => {
  //     message.success("Success!");
  //   });
  // };

  /** alphabase 광고 비율 */
  const updateAlphabasePercantage = (value: any) => {
    Apis.putConfig({ key: "alphabase_percantage", value }).then((resovle) => {
      message.success("Success!");
    });
  };
  /** 배너 변경 지연 시간(초) */
  const updateBannerDelayTimeSeconds = (value: any) => {
    Apis.putConfig({ key: "banner_delay_time_seconds", value }).then((resovle) => {
      message.success("Success!");
    });
  };

  /** 광고 보상 point */
  const updateCashUnit = (value: any) => {
    Apis.putConfig({ key: "cash_unit", value }).then((resovle) => {
      message.success("Success!");
    });
  };
  /** 크리티컬 광고 보상 */
  const updateCashUnitCritical = (value: any) => {
    Apis.putConfig({ key: "cash_unit_critical", value }).then((resovle) => {
      message.success("Success!");
    });
  };

  /** 일일 광고 클릭 제한 이후 추가 실행 시 광고 보상 point */
  const updateCashUnitExtra = (value: any) => {
    Apis.putConfig({ key: "cash_unit_extra", value }).then((resovle) => {
      message.success("Success!");
    });
  };
  /** 크리티컬 광고 노출 확률 (1/n) */
  const updatePercentageCritical = (value: any) => {
    Apis.putConfig({ key: "percentage_critical", value }).then((resovle) => {
      message.success("Success!");
    });
  };
  /** 포인트-캐시 교환비율 */
  const updatePointCashExchangeRate = (value: any) => {
    Apis.putConfig({ key: "point_cash_exchange_rate", value }).then((resovle) => {
      message.success("Success!");
    });
  };

  /** 배너 모드 */
  const updateFrontBannerMode = (value: any) => {
    Apis.putConfig({ key: "front_banner_mode", value }).then((resovle) => {
      message.success("Success!");
    });
  };
  /** 보상 지연 시간(초) */
  const updateProgressSeconds = (value: any) => {
    Apis.putConfig({ key: "progress_seconds", value }).then((resovle) => {
      message.success("Success!");
    });
  };
  /** 광고 클릭 가능여부 */
  const updateClickableBanner = (value: any) => {
    Apis.putConfig({ key: "clickable_banner", value }).then((resovle) => {
      message.success("Success!");
    });
  };

  /** 광고 클릭 가능여부 */
  const updateResourceUrl = (value: any) => {
    Apis.putConfig({ key: "resource_url", value }).then((resovle) => {
      message.success("Success!");
    });
  };

  /**
   * 유저에게 포인트 리워드를 지급합니다.
   * @param value
   */
  const postPointReward = (value: IPostPointReward) => {
    Apis.postPointReward(value).then((resovle) => {
      message.success("Success!");
    });
  };

  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PointReward postPointReward={postPointReward} />
                </Grid>
                <Grid item xs={12}>
                  <DailySavings
                    updateDayCount={updateDayCount}
                    updateDayCountExtra={updateDayCountExtra}
                    day_count={configure?.day_count.value}
                    day_count_extra={configure?.day_count_extra.value}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Rewards
                    updateCashUnit={updateCashUnit}
                    updateCashUnitCritical={updateCashUnitCritical}
                    updateCashUnitExtra={updateCashUnitExtra}
                    updatePercentageCritical={updatePercentageCritical}
                    updatePointCashExchangeRate={updatePointCashExchangeRate}
                    cash_unit={configure?.cash_unit.value}
                    cash_unit_critical={configure?.cash_unit_critical.value}
                    cash_unit_extra={configure?.cash_unit_extra.value}
                    percentage_critical={configure?.percentage_critical.value}
                    point_cash_exchange_rate={configure?.point_cash_exchange_rate.value}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Banners
                    updateFrontBannerMode={updateFrontBannerMode}
                    updateProgressSeconds={updateProgressSeconds}
                    updateClickableBanner={updateClickableBanner}
                    front_banner_mode={configure?.front_banner_mode.value}
                    progress_seconds={configure?.progress_seconds.value}
                    clickable_banner={configure?.clickable_banner.value}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ResourceUrl
                    updateResourceUrl={updateResourceUrl}
                    resource_url={configure?.resource_url.value}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default ConfigureSetting;
