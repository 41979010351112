/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Apis from "apis/remotes";
import { INotice } from "apis/response";
// import BannerCell from "layouts/pages/banners/banner-list/components/BannerCell";
// import DataTable from "layouts/pages/banners/banner-list/components/BannerTable";
import { Pagination, PaginationProps, Table } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { paramsFilter } from "utils/ParamsFilter";
import MDButton from "components/MDButton";
import { tableColumn } from "./components/data";

function NoticeList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [notices, setNotices] = useState<INotice[]>([]);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/pages/notice/notice-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
    setCurrentPage(page);
  };
  useEffect(() => {
    Apis.getNotices({ page: currentPage, perPage: 10, search, sort, order })
      .then((resolve) => {
        setNotices(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox py={3} display="flex" justifyContent="space-between">
          {/* <MDButton variant="outlined" color="dark">
            엑셀 다운
          </MDButton> */}
          <Link to={"/page/notice/new-notice"}>
            <MDButton variant="gradient" color="info">
              공지사항 등록
            </MDButton>
          </Link>
        </MDBox>

        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            scroll={{ x: "max-content" }}
            dataSource={notices}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/page/notice/detail-notice/${record.id}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NoticeList;
