// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
interface Props {
  updateResourceUrl: Function;

  resource_url: string;
}
function ResourceUrl({ updateResourceUrl, resource_url }: Props): JSX.Element {
  //resource_url 이미지 등 리소스 url prefix
  const [localResourceUrl, setLocalResourceUrl] = useState<string>(resource_url);
  const handleChangeLocalResourceUrl = (e: any) => {
    setLocalResourceUrl(e.currentTarget.value);
  };
  useEffect(() => {
    setLocalResourceUrl(resource_url);
  }, [updateResourceUrl]);
  return (
    <Card id="resource_url">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">미디어 저장 URL</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          이미지 등 리소스가 저장되는 AWS CloudFront URL입니다. 저장 시 URL Prefix로 사용됩니다.
        </MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              value={localResourceUrl}
              fullWidth
              label="Resource Url"
              inputProps={{ type: "text", autoComplete: "" }}
              onChange={handleChangeLocalResourceUrl}
            />
          </Grid>
        </Grid>
        <MDBox display="flex" justifyContent="flex-end" mt={2}>
          <MDButton
            variant="outlined"
            color="dark"
            size="small"
            onClick={() => updateResourceUrl(localResourceUrl)}
          >
            edit
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

ResourceUrl.defaultProps = {
  resource_url: "",
};

export default ResourceUrl;
