import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { IGetClientById } from "apis/response";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

interface Props {
  client?: IGetClientById;
  open: boolean;

  handleOpenState: Function;
  putClientAndUpdateData: Function;
  deleteClientAndUpdateData: Function;
}

function DetailClientDialog({
  client,
  open,
  handleOpenState,
  putClientAndUpdateData,
  deleteClientAndUpdateData,
}: Props): JSX.Element {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formProps = Object.fromEntries(formData.entries());
    console.log(formProps); // formProps는 이제 formData의 모든 항목을 객체로 가짐

    // 여기서 putClientAndUpdateData 함수를 호출하여 변경된 데이터를 업데이트
    putClientAndUpdateData(client!.id, formProps);
  };
  return (
    <Dialog
      open={open}
      onClose={() => handleOpenState()}
      // PaperProps={{
      //   component: "form",
      //   onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
      //     event.preventDefault();
      //     const formData = new FormData(event.currentTarget);
      //     const formJson = Object.fromEntries((formData as any).entries());
      //     const email = formJson.email;
      //     console.log(email);
      //   },
      // }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>클라이언트 상세 정보</DialogTitle>
        <DialogContent>
          <DialogContentText>
            클라이언트 정보를 수정하시려면 아래 내용을 수정한뒤 `수정하기` 버튼을, 클라이언트 정보를
            삭제하시려면 `삭제하기` 버튼을 클릭하세요.
          </DialogContentText>
          <TextField
            required
            defaultValue={client?.owner_name}
            margin="dense"
            id="owner_name"
            name="owner_name"
            label="이름(owner_name)"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            defaultValue={client?.trade_name}
            margin="dense"
            id="trade_name"
            name="trade_name"
            label="상호명(trade_name)"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            defaultValue={client?.email}
            margin="dense"
            id="email"
            name="email"
            label="이메일(email)"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            defaultValue={client?.business_number}
            margin="dense"
            id="business_number"
            name="business_number"
            label="사업자번호(business_number)"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            defaultValue={client?.phone}
            margin="dense"
            id="phone"
            name="phone"
            label="핸드폰번호(phone)"
            type="phone"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            defaultValue={client?.description}
            margin="dense"
            id="description"
            name="description"
            label="설명(description)"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            required
            defaultValue={client?.userId}
            margin="dense"
            id="userId"
            name="userId"
            label="유저아이디(userId)"
            type="number"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <MDButton
            variant="gradient"
            color="error"
            sx={{ height: "100%" }}
            onClick={() => {
              deleteClientAndUpdateData(client?.id);
            }}
          >
            삭제하기
          </MDButton>
          <div>
            <MDButton
              variant="gradient"
              color="secondary"
              sx={{ height: "100%" }}
              onClick={() => {
                handleOpenState();
              }}
            >
              취소하기
            </MDButton>
            <Button type="submit">수정하기</Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DetailClientDialog;
