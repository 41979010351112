// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useState } from "react";
import FormField from "../FormField";

interface Props {
  client_id: number;
  owner_name: any;
  trade_name: any;
  phone: any;
  business_number: any;
  email: any;
  description: any;
}
function ClientInfo({
  client_id,
  owner_name,
  trade_name,
  phone,
  business_number,
  email,
  description,
}: Props): JSX.Element {
  const [data, setData] = useState<Props>({
    client_id,
    owner_name,
    trade_name,
    phone,
    business_number,
    email,
    description,
  });
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">클라이언트 정보</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  이름(owner_name)
                </MDTypography>
                <MDBox mt={1}>
                  <FormField type="text" label="owner_name" name="owner_name" value={owner_name} />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  상호명(trade_name)
                </MDTypography>
                <MDBox mt={1}>
                  <FormField type="text" label="trade_name" name="trade_name" value={trade_name} />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  사업자번호(business_number)
                </MDTypography>
                <MDBox mt={1}>
                  <FormField
                    type="text"
                    label="business_number"
                    name="business_number"
                    value={business_number}
                  />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  이메일(email):
                </MDTypography>
                <MDBox mt={1}>
                  <FormField type="text" label="email" name="email" value={email} />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  핸드폰번호(phone)
                </MDTypography>
                <MDBox mt={1}>
                  <FormField type="text" label="phone" name="phone" value={phone} />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  설명(description)
                </MDTypography>
                <MDBox mt={1}>
                  <FormField
                    type="text"
                    label="description"
                    name="description"
                    value={description}
                  />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ClientInfo;
