import axios from "axios";

// const initAxios = () => {
//   axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
//   axios.defaults.withCredentials = true;
//   axios.defaults.headers.post["Content-Type"] = "application/json";
//   axios.defaults.headers.post["Accept"] = "application/json";
//   axios.defaults.timeout = 2500;
//   axios.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     (error) => {
//       if (error.response && error.response.status와 === 401) {
//         // 여기서 사용자에게 알림을 표시하거나, 로그인 페이지로 리다이렉트 할 수 있습니다.
//         return Promise.reject(new Error("세션이 만료되었습니다. 다시 로그인해주시기 바랍니다."));
//       }
//       // 그 외 에러는 그대로 반환
//       return Promise.reject(error);
//     }
//     // 401 에러가 발생하면 401 status와 함께 에러 메시지로 '세션이 만료되었습니다. 다시 로그인해주시기 바랍니다.'를 반환하고
//     // 그외에는 return 하게 해줘
//   );
// };

const initAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.timeout = 2500;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 2500,
});

export { initAxios, api };
