import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Apis from "apis/remotes";
import { IGetClient, IGetClientById } from "apis/response";
import DataTable from "./components/ClientTable";
import App from "antd/es/app";
import DetailClientDialog from "./components/DetailClientDialog";
import CreateClientDialog from "./components/CreateClientDialog";

function ClientList(): JSX.Element {
  const { message, notification } = App.useApp();
  const [data, setData] = useState<IGetClient[]>([]);
  const [client, setClient] = useState<IGetClientById>();
  const [createDialogOpenState, setCreateDialogOpenState] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleCreateClientDialogState = () => setCreateDialogOpenState((prev) => !prev);
  const handleOpenState = () => setOpen((prev) => !prev);

  /** 클라이언트 생성 */
  const postClient = (option: any) => {
    Apis.postClient(option)
      .then((resolve) => {
        const result = resolve.data.clientId;
        setData((prev) => [...prev, { id: result, ...option }]);
        message.success("Success");
        handleCreateClientDialogState();
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message ?? "",
        });
      });
  };
  /** 클라이언트 상세 정보 조회 */
  const getClientById = (id: string | number) => {
    Apis.getClientById(id)
      .then((resolve) => {
        // 성공: 상세 페이지 다이얼로그 출력
        console.log(resolve.data);
        setClient(resolve.data);
        handleOpenState();
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message ?? "",
        });
      });
  };

  const putClientAndUpdateData = (id: string | number, option: any) => {
    Apis.putClient(id, option)
      .then((resolve) => {
        setData((currentData) =>
          currentData.map((client) => (client.id === id ? { ...client, ...option } : client))
        );
        message.success("Success");
        handleOpenState();
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message ?? "",
        });
      });
  };

  const deleteClientAndUpdateData = (id: string | number) => {
    Apis.deleteClient(id)
      .then((resolve) => {
        setData((prev) => {
          return prev.filter((item) => item.id !== id);
        });
        message.success("Success");
        handleOpenState();
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message ?? "",
        });
      });
  };

  const dataTableData = {
    columns: [
      { Header: "id", accessor: "id" },
      {
        Header: "trade_name",
        accessor: "trade_name",
      },
      { Header: "owner_name", accessor: "owner_name" },
      { Header: "phone", accessor: "phone" },
      { Header: "business_number", accessor: "business_number" },
      { Header: "email", accessor: "email" },
    ],

    rows: data,
  };
  useEffect(() => {
    Apis.getClient()
      .then((resolve) => {
        setData(resolve.data.list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={() => handleCreateClientDialogState()}>
            추가하기
          </MDButton>
        </MDBox>
        <Card>
          <DataTable
            table={dataTableData}
            entriesPerPage={false}
            canSearch
            onClickDataCell={getClientById}
          />
        </Card>
      </MDBox>
      <Footer />
      <DetailClientDialog
        client={client}
        open={open}
        handleOpenState={handleOpenState}
        putClientAndUpdateData={putClientAndUpdateData}
        deleteClientAndUpdateData={deleteClientAndUpdateData}
      />
      <CreateClientDialog
        open={createDialogOpenState}
        handleCreateClientDialogState={handleCreateClientDialogState}
        postClient={postClient}
      />
    </DashboardLayout>
  );
}
export default ClientList;
