import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import MDButton from "components/MDButton";

interface Props {
  open: boolean;

  handleCreateClientDialogState: Function;
  postClient: Function;
}

/**
 * TextField의 label이 웹에서 보이는 부분이다.
 */
function CreateClientDialog({
  open,
  handleCreateClientDialogState,
  postClient,
}: Props): JSX.Element {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formProps = Object.fromEntries(formData.entries());
    console.log(formProps); // formProps는 이제 formData의 모든 항목을 객체로 가짐

    postClient(formProps);
  };
  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>클라이언트 추가하기</DialogTitle>
        <DialogContent>
          <DialogContentText>
            클라이언트 정보를 입력후 아래 `추가하기` 버튼을 클릭하세요.
          </DialogContentText>
          <TextField
            required
            margin="dense"
            id="owner_name"
            name="owner_name"
            label="이름(owner_name)"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="trade_name"
            name="trade_name"
            label="상호명(trade_name)"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="이메일(email)"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="business_number"
            name="business_number"
            label="사업자번호(business_number)"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="phone"
            name="phone"
            label="핸드폰번호(phone)"
            type="phone"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="description"
            name="description"
            label="설명(description)"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="secondary"
            sx={{ height: "100%" }}
            onClick={() => {
              handleCreateClientDialogState();
            }}
          >
            취소하기
          </MDButton>
          <Button type="submit">추가하기</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateClientDialog;
