import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import FormField from "../FormField";

interface Props {
  updateFrontBannerMode: Function;
  updateProgressSeconds: Function;
  updateClickableBanner: Function;

  front_banner_mode: boolean;
  progress_seconds: number;
  clickable_banner: boolean;
}
function Banners({
  updateFrontBannerMode,
  updateProgressSeconds,
  updateClickableBanner,

  front_banner_mode,
  progress_seconds,
  clickable_banner,
}: Props): JSX.Element {
  // front_banner_mode 배너 모드
  // progress_seconds 보상 지연 시간(초)
  // clickable_banner 광고 클릭 가능여부

  const [frontBannerMode, setFrontBannerMode] = useState<boolean>(front_banner_mode);
  const [clickableBanner, setClickableBanner] = useState<boolean>(clickable_banner);
  const [localProgressSeconds, setLocalProgressSeconds] = useState<number>(progress_seconds);

  const handleSetFrontBannerMode = () => {
    setFrontBannerMode(!frontBannerMode);
    updateFrontBannerMode(!frontBannerMode);
  };
  const handleSetClickableBanner = () => {
    setClickableBanner(!clickableBanner);
    updateClickableBanner(!clickableBanner);
  };

  const handleChangeLocalProgressSeconds = (e: any) => {
    console.log(e.currentTarget.value as number);
    setLocalProgressSeconds(e.currentTarget.value as number);
  };

  useEffect(() => {
    setFrontBannerMode(front_banner_mode);
    setClickableBanner(clickable_banner);
    setLocalProgressSeconds(progress_seconds);
  }, [front_banner_mode, clickable_banner, progress_seconds]);
  return (
    <Card id="banners">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">배너 상세 설정</MDTypography>
        </MDBox>

        <MDTypography variant="button" color="text">
          광고 배너 설정.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            배너 모드
          </MDTypography>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              <MDTypography variant="button" color="text">
                {frontBannerMode ? "Enabled" : "Disabled"}
              </MDTypography>
            </MDBox>
            <MDBox mr={1}>
              <Switch checked={frontBannerMode} onChange={handleSetFrontBannerMode} />
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />

        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            광고 클릭 가능여부
          </MDTypography>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              <MDTypography variant="button" color="text">
                {clickableBanner ? "Enabled" : "Disabled"}
              </MDTypography>
            </MDBox>
            <MDBox mr={1}>
              <Switch checked={clickableBanner} onChange={handleSetClickableBanner} />
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />

        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="button" color="text">
            보상 지연 시간(초)
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <FormField
                type="number"
                label="progress_seconds"
                value={localProgressSeconds}
                onChange={handleChangeLocalProgressSeconds}
              />
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => updateProgressSeconds(localProgressSeconds)}
            >
              edit
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}
Banners.defaultProps = {
  front_banner_mode: true,
  progress_seconds: 0,
  clickable_banner: true,
};
export default Banners;
