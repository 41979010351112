/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Apis from "apis/remotes";
import { IGetBanner } from "apis/response";
import BannerCell from "layouts/pages/banners/banner-list/components/BannerCell";
import DataTable from "layouts/pages/banners/banner-list/components/BannerTable";
import { App } from "antd";

function BannerList(): JSX.Element {
  const { message } = App.useApp();
  const [data, setData] = useState<IGetBanner[]>([]);

  const dataTableData = {
    columns: [
      {
        Header: "no",
        accessor: (row: any, rowIndex: any) => rowIndex + 1,
        Cell: ({ value }: any) => value,
      },
      { Header: "banner id", accessor: "id" },
      {
        Header: "media",
        accessor: "row",
        Cell: ({ row }: any) => (
          <BannerCell key={row.original.id} media={row.original.media} type={row.original.type} />
        ),
      },
      { Header: "type", accessor: "type" },
      { Header: "weight", accessor: "weight" },
      { Header: "duration", accessor: "duration" },
      { Header: "enabled", accessor: "enabled" },
      { Header: "client id", accessor: "client_id", Cell: ({ value }: any) => value || "" },
      { Header: "trade name", accessor: "trade_name", Cell: ({ value }: any) => value || "" },
      { Header: "target", accessor: "target" },
    ],

    rows: data,
  };

  /** 자체 광고 배너 순서 변경 UP */
  const putBannerDisplayOrderUp = (id: string | number) => {
    Apis.putBannerDisplayOrder(id, {
      work: "elevate",
    })
      .then((resolve) => {
        setData((prev) => {
          const index = prev.findIndex((banner) => banner.id === id); // 변경하려는 배너의 인덱스
          if (index > 0) {
            // 첫 번째가 아니라면
            const newBanners = [...prev];
            [newBanners[index], newBanners[index - 1]] = [newBanners[index - 1], newBanners[index]]; // 위치 교환
            // displayOrder 교환
            let temp = newBanners[index].displayOrder;
            newBanners[index].displayOrder = newBanners[index - 1].displayOrder;
            newBanners[index - 1].displayOrder = temp;
            return newBanners;
          }
          return prev;
        });
        message.success("Success");
      })
      .catch((error) => {
        // notification.error({
        //   message: error.response.data.message,
        // });
      });
  };

  /** 자체 광고 배너 순서 변경 DOWN */
  const putBannerDisplayOrderDown = (id: string | number) => {
    Apis.putBannerDisplayOrder(id, {
      work: "lower",
    })
      .then((resolve) => {
        setData((prev) => {
          const index = prev.findIndex((banner) => banner.id === id); // 변경하려는 배너의 인덱스
          if (index >= 0 && index < prev.length - 1) {
            // 마지막이 아니라면
            const newBanners = [...prev];
            [newBanners[index], newBanners[index + 1]] = [newBanners[index + 1], newBanners[index]]; // 위치 교환
            // displayOrder 교환
            let temp = newBanners[index].displayOrder;
            newBanners[index].displayOrder = newBanners[index + 1].displayOrder;
            newBanners[index + 1].displayOrder = temp;
            return newBanners;
          }
          return prev;
        });
        message.success("Success");
      })
      .catch((error) => {
        // notification.error({
        //   message: error.response.data.message,
        // });
      });
  };

  const putAdSelector = () => {
    Apis.putAdSelector()
      .then()
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    Apis.getBanner()
      .then((resolve) => {
        setData(resolve.data.list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MDButton
          color="info"
          variant="gradient"
          onClick={() => putAdSelector()}
          sx={{ fontSize: "18px" }}
        >
          수동 DB 변경 적용
        </MDButton>
      </MDBox>
      <MDBox my={3}>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BannerList;
