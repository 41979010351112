import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

import MDProgress from "components/MDProgress";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// ant design
import { App, Button, DatePicker, Form, Input, Radio } from "antd";
import Apis from "apis/remotes";

import { SearchProps } from "antd/es/input";
import dayjs from "dayjs";

function NewBannerLive(): JSX.Element {
  const { message, notification } = App.useApp();

  const [visibleTo, setVisibleTo] = useState<Date | null>(null);
  const [visibleFrom, setVisibleFrom] = useState<Date | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const [liveUrl, setLiveUrl] = useState(null);

  const handleDateChangeVisibleFrom = (newDate: Date) => {
    setVisibleFrom(newDate);
  };

  const handleDateChangeVisibleTo = (newDate: Date) => {
    setVisibleTo(newDate);
  };

  const handleCreateBanner = async (formData: any) => {
    console.log("handleCreateBanner", formData);

    if (formData.visibleFrom) {
      const formattedVisibleFrom = dayjs(formData.visibleFrom).format("YYYY-MM-DD HH:mm:ss");
      formData.visibleFrom = null;
    }

    if (formData.visibleTo) {
      const formattedVisibleTo = dayjs(formData.visibleTo).format("YYYY-MM-DD HH:mm:ss");
      formData.visibleTo = null;
    }
    console.log("handleCreateBanner", formData);

    setIsLoading(true);
    try {
      await Apis.postBanner({
        type: "live",
        ...formData,
      });
      message.success("Success!");
    } catch (error: any) {
      notification.error({
        message: error.response?.data.message ?? "",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    console.log(value);
    setLiveUrl(value); // Update the state with the entered URL
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <Card>
                <MDBox
                  mt={-3}
                  mb={3}
                  mx={2}
                  textAlign="center"
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                >
                  <MDBox mb={1}>
                    <MDTypography variant="h3" fontWeight="bold">
                      라이브 배너 추가
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="h5" fontWeight="regular" color="white">
                    카카오& 네이버 쇼핑 라이브 광고로 띄울 배너를 추가합니다.
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {/* 아이프레임 추가하고  url입력한 값으로 아이프레임 연결하셈 그리고 비율은*/}
                      <iframe
                        src={liveUrl}
                        width="300px"
                        height="480px"
                        title="카카오 라이브 쇼핑 미리보기"
                        frameBorder="0"
                        allowFullScreen
                        style={
                          {
                            // pointerEvents: "none", // Prevent interaction with the iframe
                            // transform: "scale(1)",
                            // transformOrigin: "top left",
                          }
                        }
                      ></iframe>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Form
                        layout="vertical"
                        onFinish={handleCreateBanner}
                        initialValues={{ image: "kakao" }}
                      >
                        {/* URL input */}
                        <Form.Item
                          label="URL(target)"
                          name="target"
                          rules={[
                            { required: true, type: "url", message: "Please input a valid URL!" },
                          ]}
                        >
                          <Input.Search
                            type="url"
                            placeholder="Enter target URL"
                            onSearch={onSearch}
                            enterButton="확인"
                          />
                        </Form.Item>

                        {/* Platform selection: Kakao or Naver */}
                        <Form.Item label="쇼핑 라이브 플랫폼 선택" name="image">
                          <Radio.Group>
                            <Radio value="kakao">카카오</Radio>
                            <Radio value="naver">네이버</Radio>
                          </Radio.Group>
                        </Form.Item>

                        {/* Client ID input */}
                        <Form.Item
                          label="클라이언트 아이디"
                          name="client_id"
                          rules={[
                            {
                              required: true,
                              type: "number",
                              min: 0,
                              message: "Please input a valid client ID!",
                            },
                          ]}
                          initialValue={0}
                        >
                          <Input type="number" min={0} placeholder="Enter client ID" />
                        </Form.Item>

                        {/* Weight input */}
                        <Form.Item
                          label="가중치(weight)"
                          name="weight"
                          rules={[
                            {
                              required: true,
                              type: "number",
                              message: "Please input a valid weight!",
                            },
                          ]}
                          initialValue={1}
                        >
                          <Input type="number" min={1} placeholder="Enter weight" />
                        </Form.Item>

                        {/* Duration input */}
                        <Form.Item
                          label="지속시간(duration)"
                          name="duration"
                          rules={[
                            {
                              required: true,
                              type: "number",
                              message: "Please input a valid duration!",
                            },
                          ]}
                          initialValue={30}
                        >
                          <Input type="number" placeholder="Enter duration in seconds" />
                        </Form.Item>

                        {/* DatePicker with time (visible from) */}
                        <Form.Item
                          label="시작 시간(visible from)"
                          name="visibleFrom"
                          rules={[
                            { required: true, message: "Please select a start date and time!" },
                          ]}
                        >
                          <DatePicker
                            value={visibleFrom}
                            onChange={handleDateChangeVisibleFrom}
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                          />
                        </Form.Item>

                        {/* DatePicker with time (visible to) */}
                        <Form.Item
                          label="종료 시간(visible to)"
                          name="visibleTo"
                          rules={[
                            { required: true, message: "Please select an end date and time!" },
                          ]}
                        >
                          <DatePicker
                            value={visibleTo}
                            onChange={handleDateChangeVisibleTo}
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                          />
                        </Form.Item>

                        {/* Submit button */}
                        <Form.Item>
                          <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            block
                            className="rounded-full"
                            size="large"
                          >
                            라이브 배너 생성
                          </Button>
                        </Form.Item>
                      </Form>
                    </Grid>
                  </Grid>
                  <MDProgress value={progress} />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default NewBannerLive;
