/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";

interface Props {
  visibleFrom: string;
  visibleTo: any;
  putVisibleTo: Function;
}
function Expiration({ visibleFrom, visibleTo, putVisibleTo }: Props): JSX.Element {
  const [_visibleTo, setVisibleTo] = useState<Date>(new Date(visibleTo));

  const handleDateChange = (newDate: Date) => {
    setVisibleTo(new Date(newDate));
  };

  const handleClickUpdate = () => {
    if (_visibleTo) {
      putVisibleTo(_visibleTo);
    } else {
      putVisibleTo(_visibleTo);
    }
  };

  useEffect(() => {
    setVisibleTo(visibleTo ? new Date(visibleTo) : null);
  }, [visibleTo]);
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">배너 등록 및 만료 날짜</MDTypography>
        <MDBox mt={2}>
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              등록 날짜(Display From)
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {new Date(visibleFrom).toLocaleDateString()}
            </MDTypography>
          </MDBox>
          자
          <MDBox
            mt={2}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              만료 날짜(Display To)
            </MDTypography>
            <MDDatePicker value={_visibleTo} onChange={handleDateChange} />
          </MDBox>
        </MDBox>

        <MDBox ml="auto" mt={1}>
          <Typography variant="h6" fontWeight="regular" textAlign="right">
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                size="medium"
                onClick={() => handleClickUpdate()}
              >
                수정하기
              </MDButton>
            </MDBox>
          </Typography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Expiration;
