/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../FormField";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";

interface Props {
  target: string;
  putTargetLink: Function;
}
function TargetLink({ target, putTargetLink }: Props): JSX.Element {
  const [_target, setTarget] = useState(target || "");
  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTarget(e.target.value);
  };

  useEffect(() => {
    setTarget(target || "");
  }, [target]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">클릭 시 이동하는 URL(target)</MDTypography>
        <MDBox mt={1}>
          <FormField type="text" label="target" value={_target} onChange={handleChangeData} />
        </MDBox>
        <Typography variant="h6" fontWeight="regular" textAlign="right">
          <MDBox ml="auto" mt={1}>
            <MDButton
              variant="gradient"
              color="info"
              size="medium"
              onClick={() => putTargetLink(_target)}
            >
              수정하기
            </MDButton>
          </MDBox>
        </Typography>
      </MDBox>
    </Card>
  );
}

export default TargetLink;
