import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "apis/axios";
import { App } from "antd";

const useAuthInterceptor = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const interceptor = api.interceptors.response.use(
    (response) => response, // 성공 응답 처리
    (error) => {
      // 401 에러 감지
      // if (error.response && error.response.status === 401) {
      //   navigate("/authentication/sign-in/basic"); // 로그인 페이지로 리다이렉션
      // }

      if (error.response) {
        switch (error.response.status) {
          case 401:
            notification.error({
              message: "세션이 만료되었습니다. 로그인이 필요합니다.",
              description: error.response.data.message ?? "",
              placement: "topRight",
            });
            navigate("/authentication/sign-in/basic", { replace: true }); // 로그인 페이지로 리다이렉션
            break;
          case 404:
            // 404의 경우 잠시 보류
            // notification.error({
            //   message: "해당 서비스를 찾을 수 없습니다.",
            //   description: error.response.data.message ?? "",
            //   placement: "topRight",
            // });
            // 여기에 404 에러에 대한 추가적인 처리를 할 수 있습니다.

            break;
          case 500:
            notification.error({
              message: "500 Internal Server Error - Something went wrong on server",
              description: error.response.data.message ?? "",
              placement: "topRight",
            });
            // 여기에 500 에러에 대한 추가적인 처리를 할 수 있습니다.
            break;
          default:
            notification.error({
              message: error.response.status,
              description: error.response.data.message ?? "",
              placement: "topRight",
            });
            console.log(`Error ${error.response.status}: ${error.response.statusText}`);
          // 기타 오류에 대한 처리
        }
      } else {
        // 오류 응답이 없는 경우 (네트워크 오류 등)
        notification.error({
          message: "An error occurred, but the server did not respond",
          description: error.response.data.message ?? "",
          placement: "topRight",
        });
      }

      return Promise.reject(error);
    }
  );
  useEffect(() => {
    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [interceptor]);
};

export default useAuthInterceptor;
