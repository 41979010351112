/**  */
function convertDateToYYYYMMDD(date: any): string | null {
  try {
    // date가 Date 객체인지 확인
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return null;
    }

    // YYYYMMDD 형식으로 변환
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return year + month + day;
  } catch (error) {
    return null;
  }
}
export { convertDateToYYYYMMDD };
