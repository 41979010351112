import { IPostPointReward } from "apis/request";
import {
  IGetBanner,
  IGetClient,
  IGetClientById,
  IGetConfigure,
  getList,
  IPostAdministrators,
  IGetBannerById,
  IGetFilesUpload,
  IGetClientBanner,
  INotice,
  IGetBannerRequestTest,
} from "apis/response";
import { api } from "apis/axios";

const Apis = {
  /******************************************************************************************/
  /***************************************** CREATE *****************************************/
  /******************************************************************************************/

  /** 관리자 추가 */
  postAdministrators(option: any) {
    return api.post<IPostAdministrators>("administrators", option); // HTTP body
  },

  /** 설문조사 리워드 포인트 생성 */
  postPointReward(option: IPostPointReward) {
    return api.post(`point/reward`, option);
  },

  /** 관리자 로그인 */
  postAuth(option: any) {
    return api.post("auth", option);
  },

  /** 클라이언트 추가 */
  postClient(option: any) {
    return api.post("client", option);
  },

  /** Get pre-signed url */
  postFilesUpload(type: string, mimeType: string) {
    return api.get<IGetFilesUpload>(`files/upload`, {
      params: {
        type,
        mimeType,
      },
    });
  },

  /** 자체 광고 배너 추가*/
  postBanner(option: any) {
    return api.post(`banner`, option);
  },

  /******************************************************************************************/
  /****************************************** READ ******************************************/
  /******************************************************************************************/

  /** 관리자 목록 조회 */
  getAdministrators(option: any) {
    return api.get(`administrators`, {
      params: option, // URL parameters
    });
  },

  /** 관리자 상세 조회 */
  getAdministratorsById(id: string | number) {
    return api.get(`administrators/${id}`);
  },

  /** 로그인 세션 체크 */
  getAuth() {
    return api.get("auth");
  },

  /** alphabase 활성화 배너 목록 조회 */
  getBanner() {
    return api.get<getList<IGetBanner>>("banner");
  },

  /** 배너 상세 정보 조회 */
  getBannerById(id: string | number) {
    return api.get<IGetBannerById>(`banner/${id}`);
  },

  /** 요청 배너 목록을 조회합니다. */
  getBannerRequestTest() {
    return api.get<getList<IGetBannerRequestTest>>(`banner/request/test`);
  },

  postBannerPayment(option: any) {
    return api.post(`banner/payment`, option);
  },

  /** 고객 요청 배너 추가 */
  postClientBanner(option: any) {
    return api.post(`client/banner`, option);
  },

  /** 고객 요청 배너 목록 조회 */
  getClientBanners() {
    return api.get<getList<IGetClientBanner>>(`client/banner`);
  },

  /** 활성화된 고객 목록 */
  getClient() {
    return api.get<getList<IGetClient>>("client");
  },

  /** 고객 상세 정보 조회 */
  getClientById(id: string | number) {
    return api.get<IGetClientById>(`client/${id}`);
  },

  /** 설정 값 조회 */
  getConfig() {
    return api.get<IGetConfigure>("config");
  },

  /******************************************************************************************/
  /***************************************** UPDATE *****************************************/
  /******************************************************************************************/

  /** 관리자 수정 */
  putAdministrators(id: string | number, option: any) {
    return api.put(`administrators/${id}`, option);
  },

  /** Ad Selector 호출 */
  putAdSelector() {
    return api.put(`ad/selector`);
  },

  /** 고객 상세 정보 수정 */
  putClient(id: string | number, option: any) {
    return api.put(`client/${id}`, option);
  },

  /** 설정 값 변경
   *
   * key: 변경할 설정 값의 키
   * value: 변경할 설정 값
   * bool 타입, 스트링, 숫자 등 다양한 타입을 가지고 있으니 주의 필요
   */
  putConfig(option: any) {
    return api.put("config", option);
  },

  /** 자체 광고 배너 순서 변경
   *
   *  id : 배너 id
   *  work : [elevate, lower] enum
   *  elevate: 우선순위 증가 (displayOrder 감소)
   *  lower: 우선순위 감소 (displayOrder 증가)
   *  displayOrder가 1인 배너 우선순위 증가 요청시 409 에러 발생
   *  displayOrder가 제일 큰 배너 우선순위 감소 요청시 409 에러 발생
   */
  putBannerDisplayOrder(id: string | number, option: any) {
    return api.put(`banner/displayOrder/${id}`, option);
  },

  /** 자체 광고 배너 수정 */
  putBanner(id: string | number, option: any) {
    return api.put(`banner/${id}`, option);
  },

  /******************************************************************************************/
  /***************************************** DELETE *****************************************/
  /******************************************************************************************/

  /** 관리자 삭제 */
  deleteAdministrators(id: string | number) {
    return api.delete(`administrators/${id}`);
  },

  /** 관리자 로그아웃 */
  deleteAuth() {
    return api.delete("auth");
  },

  /** 자체 광고 배너 비활성화 */
  deleteBanner(id: any) {
    return api.delete(`banner/${id}`);
  },

  /** 고객 삭제 */
  deleteClient(id: string | number) {
    return api.delete(`client/${id}`);
  },

  /** 공지사항 목록 조회 */
  getNotices(option: any) {
    return api.get<getList<INotice>>(`notice`, { params: option });
  },

  /** 공지사항 상세 조회 */
  getNoticeById(id: string | number) {
    return api.get<INotice>(`notice/${id}`);
  },

  /** 공지사항 등록 */
  postNotice(option: any) {
    return api.post(`notice`, option);
  },

  /** 공지사항 수정 */
  putNoticeById(id: string | number, option: any) {
    return api.put(`notice/${id}`, option);
  },

  /** 공지사항 삭제 */
  deleteNoticeById(id: string | number) {
    return api.delete(`notice/${id}`);
  },
};

export default Apis;
