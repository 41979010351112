import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { Button, message, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import Apis from "apis/remotes";
import { IGetBannerRequestTest } from "apis/response";
import MDButton from "components/MDButton";

function RequestBannerList(): JSX.Element {
  const navigate = useNavigate();
  const [data, setData] = useState<IGetBannerRequestTest[]>([]);
  const [loadingBannerId, setLoadingBannerId] = useState<number | null>(null); // 로딩 중인 배너 ID

  // 광고 노출 시작 함수
  const handleStartBanner = (bannerId: number) => {
    setLoadingBannerId(bannerId); // 로딩 시작
    Apis.postBannerPayment({ bannerId: bannerId })
      .then((response) => {
        message.success("광고 노출이 시작되었습니다.");
        setData((prevData) =>
          prevData.map((banner) =>
            banner.bannerId === bannerId ? { ...banner, status: "running" } : banner
          )
        );
      })
      .catch((error) => {
        message.error("오류가 발생했습니다. 다시 시도해 주세요.");
      })
      .finally(() => {
        setLoadingBannerId(null); // 로딩 종료
      });
  };

  const columns: ColumnsType<IGetBannerRequestTest> = [
    {
      title: "ID",
      dataIndex: "bannerId",
      key: "bannerId",
    },
    {
      title: "타입",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "활성화 여부",
      dataIndex: "enabled",
      key: "enabled",
      render: (enabled: number) => (
        <Tag color={enabled ? "green" : "red"}>{enabled ? "활성화" : "비활성화"}</Tag>
      ),
    },
    {
      title: "생성일",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "노출 시작일",
      dataIndex: "visibleFrom",
      key: "visibleFrom",
    },
    {
      title: "노출 종료일",
      dataIndex: "visibleTo",
      key: "visibleTo",
      render: (date: string | null) => (date ? date : "상시"),
    },
    // {
    //   title: "광고주 ID",
    //   dataIndex: "userId",
    //   key: "userId",
    // },
    {
      title: "광고주 이름",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let color = "";
        let text = "";

        if (status === "running") {
          color = "green";
          text = "노출중";
        } else if (status === "pending") {
          color = "gold";
          text = "심사 대기중";
        } else {
          color = "default";
          text = status;
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "플랜",
      dataIndex: "plan",
      key: "plan",
    },
    // {
    //   title: "노출 비율",
    //   dataIndex: "weight",
    //   key: "weight",
    // },
    {
      title: "지속 시간 (초)",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "관리",
      key: "action",
      render: (_, record) =>
        record.status === "pending" ? (
          <MDButton
            color="info"
            variant="gradient"
            loading={loadingBannerId === record.bannerId} // 로딩 상태일 때 로딩 스피너 표시
            onClick={() => handleStartBanner(record.bannerId)}
          >
            광고 노출 시작
          </MDButton>
        ) : (
          <span />
        ),
    },

    // 여기 버튼 하나 추가하고 status가 pending이면 '광고 노출 시작' 버튼 추가하고 running이면 빈칸으로 둬
  ];

  useEffect(() => {
    Apis.getBannerRequestTest().then((resolve) => {
      setData(resolve.data.list);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <Card>
          <Table
            dataSource={data}
            columns={columns}
            rowKey={(record) => record.bannerId}
            style={{ cursor: "pointer" }}
            scroll={{ x: "max-content" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/page/banners/detail-banner/${record.bannerId}`);
            //     },
            //   };
            // }}
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RequestBannerList;
